#login-component {
  background-image: url('../../assets/images/bg_login.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5rem 2.5rem;

  .card {
    display: flex;
    flex-direction: column;
    min-height: 550px;
    align-items: center;
    justify-content: space-around;
    width: 100%;
    max-width: 420px;
    background-color: white;
    border-radius: 30px;
    padding: 40px;

    @media screen and (max-width: 700px) {
      min-height: 450px;
      max-width: 380px;
      padding: 3rem;
    }

    @media screen and (max-width: 380px) {
      padding: 1.8rem;
    }

    .logo {
      @media screen and (max-width: 700px) {
        img {
          width: 100%;
        }
      }
    }

    .info {
      min-height: 227px;
      width: 100%;
      text-align: center;
      border: solid 0.1rem #eeeeee;
      display: flex;
      flex-direction: column;
      justify-content: center;

      h3 {
        font-size: 2.4rem;
        line-height: 2.8rem;
        font-weight: 500;
        margin: 0.5rem 0;


        @media screen and (max-width: 700px) {
          font-size: 1.6rem;
        }
      }

      h2 {
        font-size: 4rem;
        line-height: 3.8rem;
        font-weight: 500;
        margin-top: 0.5em;

        @media screen and (max-width: 700px) {
          font-size: 3rem;
        }
      }

      p {
        color: red;
        font-size: 1.4rem;
        margin: 1.5rem 0;
      }
    }

    .button-login {
      width: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;

      // iframe {
      //   width: 100% !important;
      //   margin: 0 !important;

      //   body {
      //     *div {
      //       width: 100%;
      //     }
      //   }
      // }
      > div {
        width: 100%;
      }

      button {
        @media screen and (max-width: 700px) {
          padding: 0.4rem
        }

        @media screen and (max-width: 380px) {
          font-size: 1.4rem;
        }
      }
    }
  }
}