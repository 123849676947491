#layout-component-id {
  .header {
    background: linear-gradient(269.96deg, #485FD8 1.31%, rgba(68, 89, 201, 0.578125) 42.93%, rgba(68, 89, 201, 0.58) 42.94%, rgba(33, 64, 235, 0.58) 99.98%);
  }

  .layout-content {
    padding: 0px 24px 24px;
    @media screen and (max-width: 800px) {
      padding: 0px 10px 24px 18px;
    }
  }
  .site-layout-background {
    background-color: #fff;

    &.site-layout-content {
      @media screen and (max-width: 800px) {
        padding: 10px 5px 5px 20px !important;

        .ant-page-header-compact {
          padding-top: 0;
          padding-bottom: 0px;
        }
      }
    }

    .marketing {
      padding: 2rem 1.5rem;
      border-bottom: 0.1rem solid #E0E0E0;
      margin-bottom: 1rem;

      h3 {
        font-size: 1.4rem;
        font-weight: 600;
        line-height: 2.6rem;
        margin-top: 2rem;
      }
    }
  }
  .ant-layout-content {
    background-color: transparent;
  }
}