#forms-components {
  background-color: #fff;
  padding: 3.5rem 4rem;

  @media screen and (max-width: 700px) {
    padding: 1rem;
    min-width: 25rem;
  }

  .ant-steps-vertical {
    max-width: 170px;
    margin: 0 auto;
  }

  .ant-form-item {
    margin-bottom: 10px;
    .ant-form-item-label {
      padding: 0 0 4px;
    }


    @media (max-width: 575px) {
      .ant-form-item-label {
        padding: 0 0 1px;
      }
    }
  }

  .form-item-button {
    .ant-form-item-control-input-content {
      display: flex;
      justify-content: flex-end;

      @media screen and (max-width: 700px){
        justify-content: center;
        flex-direction: column;

        .ant-form-item, .cancel-btn {
          width: 100% !important;
          display: inline-block;
        }
      }
    }
  }

  .form-subtitle {
    font-weight: 500;
  }

  .form-inline-responsive-base {
    vertical-align: top;
    display: inline-block;
    min-width: min-content;

    .ant-form-item-label {
      white-space: nowrap;
    }
  }

  .form-inline-responsive {
    @extend .form-inline-responsive-base; 
    width: calc(50% - 12px);

    &:first-child {
      margin-right: 2rem;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .form-inline-responsive-three {
    @extend .form-inline-responsive-base;  
    width: calc(33% - 12px);

    &:first-child, &:nth-child(2) {
      margin-right: 2rem;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .form-inline-responsive-three-right {
    @extend .form-inline-responsive-base;  
    width: calc(30% - 11px);

    &:first-child, &:nth-child(2) {
      margin-right: 2rem;
    }

    &:last-child {
      width: calc(39% - 15px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      &:last-child {
        width: 100%;
      }
    }
  }

  .form-inline-responsive-three-left {
    @extend .form-inline-responsive-base;  
    width: calc(30% - 11px);

    &:first-child, &:nth-child(2) {
      margin-right: 2rem;
    }

    &:first-child {
      width: calc(39% - 15px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      &:first-child {
        width: 100%;
      }
    }
  }

  .form-inline-responsive-three-center {
    @extend .form-inline-responsive-three;  
    width: calc(26% - 5px);

    &:first-child, &:nth-child(2) {
      margin-right: 2rem;
    }

    &:nth-child(2) {
      width: calc(45% - 14px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      &:nth-child(2) {
        width: 100%;
      }
    }
  }

  .form-inline-responsive-two-right {
    @extend .form-inline-responsive-base;  
    width: calc(24% - 12px);

    &:first-child {
      margin-right: 2rem;
    }

    &:last-child {
      width: calc(75% - 12px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      &:last-child {
        width: 100%;
      }
    }
  }

  .form-inline-responsive-two-left {
    @extend .form-inline-responsive-base; 
    width: calc(75% - 12px);

    &:first-child {
      margin-right: 2rem;
      display: grid;
      width: 100%;
    }

    &:last-child {
      width: calc(24% - 12px);
    }

    @media screen and (max-width: 800px) {
      width: 100%;

      &:last-child {
        width: 100%;
      }
    }
  }

  .form-inline-responsive-four {
    @extend .form-inline-responsive-base; 
    width: calc(25% - 16px);

    &:first-child, &:nth-child(2), &:nth-child(3) {
      margin-right: 2rem;
    }

    &.with-search-button {
      &:nth-child(3) {
        width: 6rem;
      }
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }

  .form-inline-responsive-five {
    @extend .form-inline-responsive-base; 
    width: calc(20% - 10px);

    &:first-child, &:nth-child(2), &:nth-child(3), &:nth-child(4) {
      margin-right: 2rem;
    }

    @media screen and (max-width: 800px) {
      width: 100%;
    }
  }
}