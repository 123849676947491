#card-upload-component {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  border: 1px solid #E0E0E0;
  background-color: #fff;
  border-radius: 2px;
  width: 100%;
  max-width: 42.6rem;
  height: 16.4rem;
  padding: 1rem 1.5rem;


  .card-title {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    /* or 125% */
    letter-spacing: -0.06px;
    color: #263238;
    margin-bottom: auto;

  }
  .card-description {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #4F4F4F;
    margin-bottom: 1rem;
    margin-top: 0.5rem;
  }
  .card-action-title {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #4F4F4F;
  }
}